import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import firebase from 'firebase/compat/app';
import { IUser } from "../../model/user.model";
import { IDocument } from "../generic";

export interface IEvent extends IDocument {
  name: string;
  description: string;
  numberOfUsers?: number;
  numberOfManagers?: number;
  numberOfBartenders?: number;
  logoURL?: string;
  couponURL?: string;
  bannerURL?: string;
  _geoloc: {
    lat: number;
    lng: number;
  };
  startDate?: firebase.firestore.Timestamp;
  endDate?: firebase.firestore.Timestamp;
  startDateObj?: Date;
  endDateObj?: Date;
  couponPrice?: number;
  minimumAmountOfCoupons?: number;
  allowRefund?: boolean; // mogelijkheid om eigen charity toe te voegen
  allowRefundToCharity?: boolean;
  allowRefundToMyAccount?: boolean;
  showEventHostDetails?: boolean;
  hostName?: string;
  hostCompany?: string;
  hostEmail?: string;
  hostPhone?: string;
  hostAddress?: string;
  minimumAmountForFreeTransaction?: number;
  transactionFee?: number;
  payNLAPIToken?: string;
  payNLServiceId?: string;
  payconiqAPIKey?: string;
  payconiqMerchantId?: string;
  payconiqMerchantURL?: string;
  payconiqProfileId?: string;
  // event stand koppelen aan category
}

export interface IEventTokenGeneration extends IDocument {
  fromUserId: string;
  toUserId: string;
  amount: number;
  status: number;
}

export interface IEventTransaction extends IDocument {
  user_id: string;
  type: string;
  description: string;
  amount: number;
  event_id: string;
  timestamp?: firebase.firestore.Timestamp;
  users: string[];
  order?: any;
  fromUser?: IUser;
  toUser?: IUser;
}

export interface IEventUser extends IDocument {
  email?: string;
  coupons?: number;
  qrcode?: string;
  bartender?: boolean;
  manager?: boolean;
  tokengenerator?: boolean;
  user_id: string;
  user?: IUser;
  categories?: Array<string>;
  nickName?: string;
  event_id: string;
  active: boolean;
}

export interface IEventUserCategory extends IDocument {
  description: string;
}

export interface IEventStand extends IDocument {
  name: string;
  categories?: Array<string>;
  categories_string?: string;
  bankaccount?: string;
  email?: string;
  vat_nr?: string;
  address?: string;
  telephone_nr?: string;
  client_id?: string;
}

export interface IEventSepa extends IDocument {
  date_generated: firebase.firestore.Timestamp;
  file: string;
  status: string;
}


export interface IInvoiceRequest extends IDocument {
  city: string;
  company: string;
  eventId: string;
  postalCode: string;
  street: string;
  userId: string;
  vatNr: string;
}
@Injectable({
  providedIn: "root",
})
export class EventService {
  constructor(private afStore: AngularFirestore) { }

  addEvent() {
    return this.afStore.collection<IEvent>("/event").add({
      name: "New event",
      description: "New event description",
      _geoloc: {
        lat: 10,
        lng: 10,
      },
    });
  }

  getEvents() {
    return this.afStore
      .collection<IEvent>("/event")
      .valueChanges({ idField: "key" });
  }

  getEvent(id: string) {
    return this.afStore
      .doc<IEvent>(`/event/${id}`)
      .valueChanges({ idField: "key" });
  }

  getEventStands(id: string) {
    return this.afStore
      .collection<IEventStand>(`/event/${id}/eventstand`)
      .valueChanges({ idField: "key" });
  }

  getEventSepa(id: string) {
    return this.afStore
      .collection<IEventSepa>(`/event/${id}/sepa`, ref => ref.orderBy('date_generated', 'desc'))
      .valueChanges({ idField: "key" });
  }

  getEventStand(eventId: string, key: string) {
    return this.afStore
      .doc<IEventStand>(`/event/${eventId}/eventstand/${key}`)
      .ref.get();
  }

  getEventUserCategories(eventId: string) {
    return this.afStore
      .collection<IEventUserCategory>(
        `/event/${eventId}/eventusercategory`,
        (ref) => ref.orderBy("description", "asc")
      )
      .valueChanges({ idField: "key" });
  }

  addEventUserCategory(eventId: string, description: string) {
    return this.afStore
      .collection<IEventUserCategory>(`/event/${eventId}/eventusercategory`)
      .add({
        description: description,
      });
  }

  addEventUser(eventId: string, userId: string) {
    return this.afStore
      .doc<IEventUser>(`/event/${eventId}/eventuser/${userId}`)
      .set(
        {
          user_id: userId,
          event_id: eventId,
          active: true,
        },
        { merge: true }
      );
  }

  updateEvent(event: IEvent) {
    return this.afStore.doc<IEvent>(`/event/${event.key}`).update({
      name: event.name,
      description: event.description,
      logoURL: event.logoURL,
      couponURL: event.couponURL,
      bannerURL: event.bannerURL,
      _geoloc: {
        lat: event._geoloc.lat,
        lng: event._geoloc.lng,
      },
      startDate: firebase.firestore.Timestamp.fromDate(
        event.startDateObj
      ),
      endDate: firebase.firestore.Timestamp.fromDate(event.endDateObj),
      couponPrice: event.couponPrice,
      minimumAmountOfCoupons: event.minimumAmountOfCoupons,
      allowRefund: event.allowRefund,
      allowRefundToCharity: event.allowRefundToCharity,
      allowRefundToMyAccount: event.allowRefundToMyAccount,
      showEventHostDetails: event.showEventHostDetails,
      hostName: event.hostName,
      hostCompany: event.hostCompany,
      hostEmail: event.hostEmail,
      hostPhone: event.hostPhone,
      hostAddress: event.hostAddress,
      minimumAmountForFreeTransaction: event.minimumAmountForFreeTransaction,
      transactionFee: event.transactionFee,
      payNLAPIToken: event.payNLAPIToken,
      payNLServiceId: event.payNLServiceId,
      payconiqAPIKey: event.payconiqAPIKey,
      payconiqMerchantId: event.payconiqMerchantId,
      payconiqMerchantURL: event.payconiqMerchantURL,
      payconiqProfileId: event.payconiqProfileId,
    });
  }

  updateEventstand(eventId: string, id: string, stand: IEventStand) {
    if (!id) {
      return this.afStore
        .collection<IEventStand>(`/event/${eventId}/eventstand`)
        .add({
          name: stand.name,
          categories: stand.categories,
          address: stand.address,
          email: stand.email,
          vat_nr: stand.vat_nr,
          bankaccount: stand.bankaccount,
          telephone_nr: stand.telephone_nr,
          client_id: stand.client_id
        });
    }

    return this.afStore
      .doc<IEventStand>(`/event/${eventId}/eventstand/${id}`)
      .update({
        name: stand.name,
        categories: stand.categories,
        address: stand.address,
        email: stand.email,
        vat_nr: stand.vat_nr,
        bankaccount: stand.bankaccount,
        telephone_nr: stand.telephone_nr,
        client_id: stand.client_id
      });
  }

  deleteEventstand(eventId: string, eventstandId: string) {
    return this.afStore
      .doc<IEventStand>(`/event/${eventId}/eventstand/${eventstandId}`)
      .delete();
  }

  deleteSepa(eventId: string, sepaId: string) {
    return this.afStore
      .doc<IEventSepa>(`/event/${eventId}/sepa/${sepaId}`)
      .delete();
  }

  getEventIdFromURL(url: string) {
    url = url.replace("/pages/", "");
    return url.substring(0, url.indexOf("/"));
  }

  getEventUser(eventId: string, userId: string) {
    return this.afStore
      .doc<IEventUser>(`/event/${eventId}/eventuser/${userId}`)
      .valueChanges({ idField: "key" });
  }

  clearTokensForEventUsers(eventId: string) {
    let i = 0;
    return this.afStore.collection<IEventUser>(`/event/${eventId}/eventuser`).get().forEach((snapshot) => {
      snapshot.forEach((eventuser) => {
        eventuser.ref.update({
          coupons: 0
        })
        console.log(i++)
        /*
        eventuser.payload.doc.ref.update({
          coupons: 0
        })
        */
      })
    });
  }

  updateEventUser(eventId: string, userId: string, name: string) {
    return this.afStore
      .doc<IEventUser>(`/event/${eventId}/eventuser/${userId}`)
      .update({
        nickName: name,
      });
  }

  searchUser(eventId: string, searchValue: string) {
    return this.afStore
      .collection<IEventUser>(`/event/${eventId}/eventuser`, (ref) =>
        ref.where("email", "==", searchValue)
      )
      .valueChanges({ idField: "key" });
  }

  createTransaction(
    eventId: string,
    userId: string,
    type: string,
    description: string
  ) {
    return this.afStore
      .collection<IEventTransaction>(`/event/${eventId}/transaction`)
      .add({
        user_id: userId,
        amount: 0,
        type: type,
        description: description,
        event_id: eventId,
        users: [userId],
      });
  }

  getUsersForCategories(eventId: string, categories: string[]) {
    return this.afStore
      .collection<IEventUser>(`/event/${eventId}/eventuser`, (ref) =>
        ref.where("categories", "array-contains-any", categories)
      )
      .valueChanges({ idField: "key" });
  }

  getTransactions(eventId: string, userId: string) {
    return this.afStore
      .collectionGroup<IEventTransaction>(`transaction`, (ref) =>
        ref
          .where("users", "array-contains", userId)
          .where("event_id", "==", eventId)
          .orderBy("timestamp", "desc")
      )
      .valueChanges({ idField: "key" });
  }

  getInvoiceRequests(eventId: string) {
    return this.afStore
      .collection<IInvoiceRequest>(`event/${eventId}/invoicerequest`)
      .valueChanges({ idField: "key" });
  }

  deleteInvoiceRequest(eventId: string, key: string) {
    return this.afStore
      .doc<IInvoiceRequest>(`event/${eventId}/invoicerequest/${key}`)
      .delete();
  }

  generateNewQRCode(eventId: string, userId: string) {
    return this.afStore
      .doc<IEventUser>(`user/${userId}`)
      .update({ qrcode: null });
  }

  deactivateEventUser(eventId: string, userId: string) {
    return this.afStore.doc(`event/${eventId}/eventuser/${userId}`).update({
      active: false,
    });
  }

  removeCategoryFromUser(eventId: string, userId: string, categoryId: string) {
    return this.afStore.doc(`event/${eventId}/eventuser/${userId}`).update({
      categories: firebase.firestore.FieldValue.arrayRemove(categoryId),
    });
  }

  addTokenGeneration(
    fromUserId: string,
    toUserId: string,
    eventId: string,
    amount: number
  ) {
    return this.afStore
      .collection<IEventTokenGeneration>(`/event/${eventId}/tokengeneration`)
      .add({
        fromUserId,
        toUserId,
        amount,
        status: 0,
      });
  }

  addCategoryToEventUser(eventId: string, userId: string, categoryId: string) {
    return this.afStore.doc(`event/${eventId}/eventuser/${userId}`).update({
      categories: firebase.firestore.FieldValue.arrayUnion(categoryId),
    });
  }

  deactivateEventUsers(eventId: string) {
    return this.afStore
      .collection(`event/${eventId}/eventuser`)
      .get()
      .forEach((eventuser) => {
        eventuser.forEach((eventuserdoc) => {
          console.log(eventuserdoc.data());

          eventuserdoc.ref.update({
            active: false,
          });
        });
      });
  }

  generateSepa(eventId: string, sendMails: boolean) {
    return this.afStore
    .collection(`event/${eventId}/sepa`)
    .add({
      date_generated: firebase.firestore.FieldValue.serverTimestamp(),
      file: 'Generating...',
      status: 'generating',
      sendMails
    })
  }

  deleteUserCategory(eventId: string, id: string) {
    return this.afStore
      .doc(`event/${eventId}/eventusercategory/${id}`)
      .delete()
      .then(() => {
        return this.afStore
          .collection<IEventUser>(`/event/${eventId}/eventuser`, (ref) =>
            ref.where("categories", "array-contains-any", [id])
          )
          .ref.get()
          .then((results) => {
            results.forEach((result) => {
              result.ref.update({
                categories:
                  firebase.firestore.FieldValue.arrayRemove(id),
              });
            });
          });
      });
    // make sure that the category is removed from the end user in a function !
  }
}
